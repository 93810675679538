$font-size-base: 0.875rem;
$font-family-base: "Inter", sans-serif;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import "node_modules/bootstrap/scss/bootstrap";

body {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: auto;
  letter-spacing: -0.1px !important;
  line-height: 1.4 !important;
}

.dk-card-body {
  transition: all 10s linear;
}

.dk-card {
  border: none;
}

.dk-shadow-sm {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.dk-card-shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

.dk-card-shadow::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
}

.dk-card-shadow:hover {
  transform: scale(1, 1);
}

.dk-card-shadow:hover::after {
  opacity: 1;
}

.dk-card-header {
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.svg-danger svg {
  fill: var(--danger);
}

.svg-gray svg {
  fill: #c1c2c1;
}

.svg-success svg {
  fill: var(--success);
}

.svg-warning svg {
  fill: #856404;
}

.svg-black svg {
  fill: black;
}

.text-alert-warning {
  color: #856404;

  :hover {
    color: #856404;
  }
}

.dk-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dk-alert {
  border: none;
}

.scale-opacity-enter {
  transform: rotateX(-15deg);
  transform-origin: top;
  opacity: 0;
}

.scale-opacity-appear {
  transform: rotateX(-15deg);
  transform-origin: top;
  opacity: 0;
}

.scale-opacity-appear-active {
  transform: rotateX(0deg);
  transform-origin: top;
  transition: transform 300ms, opacity 300ms;
  opacity: 1;
}

.scale-opacity-enter-active {
  transform: rotateX(0deg);
  transform-origin: top;
  transition: transform 300ms, opacity 300ms;
  opacity: 1;
}

.scale-opacity-exit {
  opacity: 1;
}

.scale-opacity-exit-active {
  transform: scale(0.9);
  transition: transform 300ms, opacity 300ms;
  opacity: 0;
}

th {
  font-weight: 400;
  font-size: 0.875em;
  color: var(--gray);
}

.dk-table-hover tbody tr:hover {
  background-color: #f9f9f9;
}

.dk-table {
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
a:focus,
a:active,
a:hover,
select:hover,
select:active,
select:focus,
.btn:active,
.btn.active {
  outline: none !important;
  box-shadow: none !important;
}

.dk-navlink-hover:hover {
  background-color: #f2f2f2;
}

.dk-navlink-hover.active:hover {
  background-color: transparent;
}

.dk-navlink {
  color: var(--dark);
}

.dk-navlink:hover {
  color: var(--dark);
}

.dk-navlink.active {
  color: #800020;
}

.dk-navlink.active .svg-icon svg {
  fill: #800020;
}

.ease-in-out-back {
  -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
}

.ease-in {
  transition: all 0.3s ease-in 0s;
}

.collapse {
  &-enter {
    max-height: 0px;
  }

  &-enter-active {
    max-height: 100rem;
    position: relative;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  }

  &-exit {
    max-height: 100rem;
  }

  &-exit-done {
    display: none;
  }

  &-exit-active {
    max-height: 0px;
    position: relative;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  }
}

.sidebar-logo {
  display: inline-flex;
  align-self: center;
  margin: 0;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.table-no-padding {
  th {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  td {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.svg-flipped svg {
  transform: rotate(180deg);
}

.page-selector {
  min-width: 4rem;
}

.border-wine {
  border-color: #800020 !important;
}

.text-wine {
  color: #800020 !important;
}

.filter-selector {
  min-width: 7rem;
}

.deskera-datepicker {
  .row {
    margin-right: 0;
    margin-left: 0;
  }

  .w-500 {
    width: max-content !important;
  }
}

.DKLiskPicker2 {
  .row {
    margin-right: unset;
    margin-left: unset;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.5rem;
    box-sizing: border-box;
  }
}

.d-row-flex {
  .row {
    margin-right: unset;
    margin-left: unset;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.5rem;
    box-sizing: border-box;
  }
}

.card-without-background {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 0.25rem;
}
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.svg-icon {
  display: inline-flex;
  align-self: center;
}

.svg-icon svg {
  height: 1em;
  width: 1em;
}

.svg-icon.svg-baseline {
  position: relative;
  top: 0.125em;
}

.svg-icon-lg svg {
  height: 1.25em;
  width: 1.25em;
}

.svg-icon-lg.svg-baseline svg {
  position: relative;
  top: 0.15675em;
}

.svg-icon-sm svg {
  height: 0.8em;
  width: 0.8em;
}

.svg-icon-sm.svg-baseline svg {
  position: relative;
  top: 0em;
}

.svg-disabled svg {
  fill: gray;
}

.svg-white svg {
  fill: white;
}

.shadow-lg {
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

.toast-list {
  width: 100%;
  z-index: 40;
  position: absolute;
  top: 60px;
  right: 20px;
  width: 300px;
}

.w-600 {
  width: 600px;
  max-width: 600px;
  min-width: 600px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.align-items-center {
  align-items: center;
}

.input-style{
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
}


.zIndex-1-important {
  z-index: 1 !important;
}
